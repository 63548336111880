<template>
  <AppInfo/>
</template>

<script>
import AppInfo from "@/components/AppInfo";

export default {
  name: 'App',
  components: {
    AppInfo
  }
}
</script>

<style>
html,body{
  margin: 0;
}
#app{
}
body{
  color: #363412;
  font-family: NotoSansHans-Regular,AvenirNext-Regular,proxima-nova,"Hiragino Sans GB","Microsoft YaHei","WenQuanYi Micro Hei","Open Sans","Helvetica Neue",Arial,sans-serif,"Source Han Sans";
}
h1, h2, h3, h4, h5, h6{
  color: #585f69;
  margin-top: 5px;
  text-shadow: none;
  font-weight: 400;
  font-family: NotoSansHans-Regular,AvenirNext-Regular,proxima-nova,"Hiragino Sans GB","Microsoft YaHei","WenQuanYi Micro Hei","Open Sans","Helvetica Neue",Arial,sans-serif;
}
</style>
