<template>
  <div v-if="!loading" class="page" ref="root">
    <div class="app-title">
      <div class="search-box">
        <div class="input-box">
          <div class="scale-box"><div class="scale-box-icon"></div><div class="scale-box-text">你想看的这里都有</div></div>
        </div>
      </div>
      <div class="title-text"><img src="@/assets/images/download_img_text@2x.png"/></div>
      <div class="title-text-tips"><img src="@/assets/images/download_img_leftred@2x.png"/><span>专属你的社交空间</span><img src="@/assets/images/download_img_rigtred@2x.png"/></div>
    </div>
    <div v-if="isPC||isMac||isWin" class="both-platform">
      <div class="app-info">
        <img class="app-logo" src="@/assets/images/logo.png"/>
        <img class="app-name" src="@/assets/images/SHOW2@2x.png"/>
      </div>
      <div class="download-text"><img src="@/assets/images/download_img_leftblick@2x.png"/><span>这里，遇见你感兴趣的那个TA</span><img src="@/assets/images/download_img_rigtblick@2x.png"/></div>
      <div class="platform-box">
        <div class="app-box android-box" @click="installApk">
          <div class="app-icon"></div>
          <div class="app-text">Android</div>
          <div class="download-now">立即下载</div>
        </div>
        <div class="split">&nbsp;</div>
        <div class="app-box ios-box">
          <div class="app-icon"></div>
          <div class="app-text">iOS</div>
          <div class="download-now">扫描下方<br/>二维码</div>
        </div>
      </div>
      <div class="platform-version">
        <div class="version-box">
          <div class="version">版本:{{ apkVersion }}</div>
        </div>
        <div class="split">|</div>
        <div class="version-box">
          <div class="version">{{ version ? '版本:' + version : '暂无可用版本' }}</div>
        </div>
      </div>
      <div class="qr-box">
        <vue-qr :logoSrc="require('@/assets/images/logo.png')" :text="qrText" :size="400" :logoMargin="2"
                :logoCornerRadius="18" colorDark="#383A4A" :logo-scale="0.22" :margin="30"></vue-qr>
        <div class="scan-info">手机扫码访问</div>

        <div class="install-notice">
          <div class="notice-p">安装遇到困难，请<a :href="docUrl">查看帮助</a></div>
        </div>
      </div>
    </div>
    <div v-else class="mobile-box">
      <div class="app-info">
        <img class="app-logo" src="@/assets/images/logo.png"/>
        <img class="app-name" src="@/assets/images/SHOW2@2x.png"/>
      </div>
      <div class="download-text"><img src="@/assets/images/download_img_leftblick@2x.png"/><span>这里，遇见你感兴趣的那个TA</span><img src="@/assets/images/download_img_rigtblick@2x.png"/></div>
      <div class="version-info">
        <div class="platform">适用于{{ isIOS ? 'iOS' : 'Android' }}设备</div>
        <div v-if="isAndroid" class="platform-notice">适用Android5.0及以上系统</div>
        <div class="version" v-if="!hasVip && apps.length">版本:{{ version || apkVersion }} {{ build ? "(" + build + ")" : "" }}</div>
      </div>
      <template v-if="isIOS">
        <div v-if="apps.length > 0 || hasVip" class="install-box">
          <template v-if="apps.length > 0">
            <a v-if="apps[0]" class="install-btn normal" href="javascript:"
               @click="install(apps[0], vip)">{{ apps[1] ? '安装包1' : '安装' }}</a>
            <a v-if="apps[1]" class="install-btn normal" href="javascript:" @click="install(apps[1], vip)">安装包2</a>
          </template>
          <template v-if="hasVip">
            <a v-if="hasVip" class="install-btn" href="javascript:" @click="showVipInstall">女神版</a>
          </template>
        </div>
      </template>
      <div v-else class="install-box">
        <a class="install-btn normal" href="javascript:" @click="installApk">下载</a>
      </div>
      <div class="qr-box">
        <vue-qr class="qr-img" :logoSrc="require('@/assets/images/logo.png')" :text="qrText" :size="400" :logoMargin="2"
                :logoCornerRadius="18" colorDark="#383A4A" :logo-scale="0.22" :margin="30"></vue-qr>

        <div v-if="isIOS" class="install-notice">
          <div class="notice-p">安装遇到困难，请<a :href="docUrl">查看帮助</a></div>
        </div>
        <div class="version" v-if="version || apkVersion">版本:{{ version || apkVersion }}</div>
      </div>


      <div v-if="isWeibo || isQQ || isWx" class="mask">
        <img v-if="isAndroid" src="@/assets/images/mask1.png">
        <img v-else src="@/assets/images/mask.png">
      </div>

      <div v-if="showInstall" class="mask">
        <div class="alert-box">
          <div class="now-download" id="now-download">请等待安装完成，然后开始设置</div>
          <div class="a1">若安装后无法正常使用，请删除旧版APP</div>
          <div class="a2">请务必按以下步骤进行设置，详细 <a :href="docUrl"
                                             style="color: #00A4FF;">安装教程</a>。
          </div>
          <div class="course" style="max-width: 100%;">
            <img style="width: 100%;max-width: 100%;" :src="require('@/assets/picture/step_' +currentIdx+ '.gif')">
            <div class="course_p">{{ stepMessages[currentIdx] }}</div>
          </div>
          <div class="close-btn2" @click="closeInstall"></div>
        </div>
      </div>
    </div>
  </div>
  <van-dialog v-model:show="showVipLogin" title="女神验证" show-cancel-button :close-on-click-overlay="false" :before-close="installVip">
    <div class="verify-tips">
      如果还<span>未注册认证</span>的女神，请先下载普通版，<span>通过认证后</span>，再下载女神版
    </div>
      <van-field
          v-model="mobile"
          label="手机号"
          placeholder="手机号"
      />
    <div class="code-row">
      <van-field
          v-model="code"
          label="验证码"
          placeholder="验证码"
      />
      <a :class="'send-code' + (countDown>0?' disabled':'')" href="javascript:" @click="sendCode">{{countDown>0?"重新发送(" +countDown+ ")" : "发送验证码"}}</a>
    </div>
  </van-dialog>
</template>

<script>
import {post, getNoCache} from "@/utils/net";
import {loading, hideLoading} from "@/utils/toast";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import {Toast} from "vant";

export default {
  name: "AppInfo",
  components: {
    vueQr,
  },
  data: function () {
    return {
      version: "",
      build: "",
      ua: navigator.userAgent,
      appParam: {
        id: '',
        release: 1
      },
      apps: [],
      switchIdx: 0,
      currentIdx: 0,
      showInstall: false,
      stepMessages: ["第1步：取消弹窗提示", "第2步：打开桌面的“设置”功能", "第3步：打开“通用”设置", "第4步：打开“VPN与设备管理”", "第5步：点击“企业级APP”", "第6步：点击蓝色文字“信任”", "第7步：点击弹窗中的“信任”,完成设置"],
      isAndroid: false,
      isIOS: false,
      isQQ: false,
      isPC: false,
      isWin: false,
      isMac: false,
      isWx: false,
      isWeibo: false,
      isSafari: false,
      loading: true,
      hasVip: false,
      showVipLogin: false,
      mobile: "",
      code:"",
      apkVersion: "",
      apkUrl: "",
      docUrl: "",
      vip: false,
      countDown:0,
      qrText: window.location.href,
      prefix: "itms-services://?action=download-manifest&url=",
      ipUrl: "https://swapi.showow.cc/User/AddUserIp?test=mincro&code=",
      iOSMinVersion:[]
    };
  },
  methods: {
    checkBrowser: function () {
      let isIosQQ = (/(iPhone|iPad|iPod|iOS)/i.test(this.ua) && /\sQQ/i.test(this.ua));
      let isAndroidQQ = (/(Android)/i.test(this.ua) && /MQQBrowser/i.test(this.ua) && /\sQQ/i.test(this.ua));
      let isAndroid = this.ua.indexOf('Android') > -1 || this.ua.indexOf('Adr') > -1; //android终端
      this.isQQ = isIosQQ || isAndroidQQ;
      this.isAndroid = isAndroid;
      this.isWeibo = /weibo/i.test(this.ua);
      this.isWx = /MicroMessenger/i.test(this.ua);

      // check PC
      let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (this.ua.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      this.isPC = flag;

      let agent = this.ua.toLowerCase();
      let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      let MAC = !isIOS && /macintosh|mac os x/i.test(navigator.userAgent) && !window.MSStream;

      this.isWin = agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0 || agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0

      this.isIOS = isIOS;
      this.isMac = MAC;

      this.isSafari = this.ua.indexOf("Safari") > -1 && this.ua.indexOf("Chrome") === -1; //判断是否Safari浏览器

    },
    loopImage: function (currentIdx) {
      currentIdx = currentIdx + 1;
      if (currentIdx > 6) {
        currentIdx = 0;
      }
      let vm = this;
      this.currentIdx = currentIdx;
      clearTimeout(this.switchIdx);
      this.switchIdx = setTimeout(function () {
        vm.loopImage(vm.currentIdx);
      }, 4000);
    },
    increaseDownload: async function (uuid, vip) {
      let l = loading();
      try {
        let res = await post("https://test.wemiquan.com/Home/IncreaseDownload", {uuid, release: vip && this.appParam.release === 1 ? 2 : this.appParam.release});
        if (res.code === 404) {
          return false;
        }
      } catch (e) {
        console.log(e);
      }
      hideLoading(l);
      return true;
    },
    checkScroll: function (e) {
      let top = this.$refs["root"].scrollTop;
      if (top === 0) {
        return;
      }
      e.preventDefault();
    },
    install: async function (item, vip) {
      if (this.iOSMinVersion && this.iOSMinVersion.length > 0) {
        const regex = /iPhone OS (\d+)_(\d+)(_(\d+))? /;
        const ua = navigator.userAgent;
        const matched = ua.match(regex);
        if (matched) {
          if (matched[1] < this.iOSMinVersion[0]
              || matched[1] === this.iOSMinVersion[0] && matched[2] < this.iOSMinVersion[1]
              || matched[1] === this.iOSMinVersion[0] && matched[2] === this.iOSMinVersion[1] && (typeof (matched[4]) == "undefined" || matched[4] < this.iOSMinVersion[2])) {
            Toast.fail("系统版本低于" + this.iOSMinVersion.join(".") + "，不能安装");
            return;
          }
        }
      }
      this.showInstall = true;
      this.loopImage(-1);
      let res = await this.increaseDownload(item.uuid, vip);
      if (res) {
        window.location.href = this.prefix + item.plistUrl;
      } else {
        window.location.reload();
      }
    },
    showVipInstall: async function(){
      if (this.iOSMinVersion && this.iOSMinVersion.length > 0) {
        const regex = /iPhone OS (\d+)_(\d+)(_(\d+))? /;
        const ua = navigator.userAgent;
        const matched = ua.match(regex);
        if (matched) {
          if (matched[1] < this.iOSMinVersion[0]
              || matched[1] === this.iOSMinVersion[0] && matched[2] < this.iOSMinVersion[1]
              || matched[1] === this.iOSMinVersion[0] && matched[2] === this.iOSMinVersion[1] && (typeof (matched[4]) == "undefined" || matched[4] < this.iOSMinVersion[2])) {
            Toast.fail("系统版本低于" + this.iOSMinVersion.join(".") + "，不能安装");
            return;
          }
        }
      }
      const token = window.localStorage.downloadToken;
      if(token){
        let l = loading();
        try {
          let r = await post("https://fapi.showow.cc/Api/GetVersion", {id: this.appParam.id, mobile: "", code:token});
          if(r && r.code === 200){
            hideLoading(l);
            this.showVipLogin = false;
            await this.install(r.data, true);
            return;
          }
        } catch (e) {
          console.log(e);
        }
        hideLoading(l);
      }
      this.showVipLogin = true;
    },
    installVip: async function (action) {
      if(action === "cancel"){
        this.showVipLogin = false;
        return;
      }
      if(!this.mobile){
        Toast({message:"请输入手机号"});
        return;
      }
      if(!this.code){
        Toast({message:"请输入验证码"});
        return;
      }
      let l = loading();
      try {
        let r = await post("https://fapi.showow.cc/Api/GetVersion", {id: this.appParam.id, mobile: this.mobile, code:this.code});
        if(r && r.code === 200){
          this.showVipLogin = false;
          this.code = "";
          window.localStorage.downloadToken = r.data.token;
          await this.install(r.data, true);
        }else{
          window.localStorage.downloadToken = undefined;
          Toast({message:r.msg || "登录失败"});
        }
      } catch (e) {
        Toast({message:"操作失败"});
      }
      hideLoading(l);
    },
    installApk: function () {
      window.location.href = this.apkUrl;
    },
    closeInstall: function () {
      clearTimeout(this.switchIdx);
      this.currentIdx = 0;
      this.showInstall = false;
    },
    sleep: async function (time) {
      return await new Promise((resolve) =>{
        setTimeout(resolve, time);
      });
    },
    countDownTick: async function(){
      for(let i=60;i>=0;i--){
        this.countDown = i;
        await this.sleep(1000);
      }
    },
    sendCode: async function() {
      if(this.countDown > 0){
        return;
      }
      const mobile = this.mobile;
      if(!mobile){
        Toast({message:"请输入手机号"});
        return;
      }
      let l = loading();
      try {
        let r = await post("https://fapi.showow.cc/Api/SendVersionCode", {mobile: this.mobile});
        if(r && r.code === 200){
          this.countDown = 60;
          this.countDownTick().then();
        }else{
          Toast({message:r.msg || "发送失败"});
        }
      } catch (e) {
        console.log(e);
        Toast({message:"发送失败"});
      }
      hideLoading(l);
    },
    loadApp: async function () {
      if (this.isIOS || this.isPC || this.isMac || this.isWin) {
        let l = loading();
        try {
          let checkOK = true;
          let res = await post("https://test.wemiquan.com/Home/Last", this.appParam);
          if (res.code === 200) {
            let data = res.data;
            if (data && data.length > 0) {
              this.version = data[0].appVersion;
              this.build = data[0].build;
              this.apps = data;
              checkOK = true;
            } else {
              checkOK = false;
            }
          }
          if (!checkOK) {
            this.version = "暂无版本";
          }
        } catch (e) {
          console.log(e);
        }
        hideLoading(l);
      }
    },
    loadIosApp: async function () {
      if (this.isIOS) {
        let l = loading();
        try {
          let r = await post("https://fapi.showow.cc/Api/HasVersion", {id: this.appParam.id});
          this.hasVip = !!(r && r.code === 200);
        } catch (e) {
          console.log(e);
        }
        hideLoading(l);
      }
    },
    async checkIP() {
      let query = window.location.search;
      let arr = query.replace(/\?/g, '&').split('&');
      let param = {};
      for (let i = 0; i < arr.length; i++) {
        let p = arr[i];
        if (!p) {
          continue;
        }
        let pp = p.split('=');
        if (pp.length !== 2) {
          continue;
        }
        param[pp[0]] = pp[1];
      }
      if (param['code']) {
        await getNoCache(this.ipUrl + param['code'] + '&s=' + (param['s'] || ''));
      }
    }
  },
  async mounted() {
    try {
      let config = await getNoCache(window.configFile || "/config.json");
      if (config) {
        this.appParam.id = config.id;
        this.appParam.release = config.release;
        this.apkVersion = config.apkVersion;
        this.apkUrl = config.apkUrl;
        this.docUrl = config.docUrl;
        this.vip = config.vip;
        this.iOSMinVersion = config.iOSMinVersion || this.iOSMinVersion;
        this.ipUrl = config.ipUrl || this.ipUrl;
      }
    } catch (e) {
      console.log(e);
    }
    await this.checkIP();

    this.checkBrowser();
    await this.loadApp();
    await this.loadIosApp();
    this.loading = false;
  }
}
</script>

<style scoped>

.page {
  text-align: center;
  max-width: 640px;
  margin: auto;
  overflow: auto;
  height: 100%;
  position: relative;
  padding-bottom: 30px;
}

.page:after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: url("~@/assets/images/download_img_bg@2x.png") no-repeat center;
  background-size: cover;
  max-width: 640px;
  margin: auto;
}

.input-box{
  background: url("~@/assets/images/search_bg@2x.png") no-repeat center;
  width: 212px;
  height: 24px;
  background-size: 212px 34px;
  padding: 5px 0;
}
.scale-box{
  background: #FFDEEF;
  border-radius: 13px;
  margin-left: 74px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  width: 134px;
}
.scale-box-icon {
  background: url("~@/assets/images/icon_scale@2x.png") no-repeat center;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
}
.scale-box-text {
  font-size: 12px;
  font-family: PingFang SC, Monaco, Avenir, Helvetica, serif;
  font-weight: 400;
  color: #FF5CB0;
  line-height: 17px;
}

.app-title {
  text-align: left;
  margin-bottom: 102px;
}
.search-box{
  margin-top: 77px;
  margin-left: 29px;
}

.title-text img {
  width: 100%;
  margin-top: 40px;
}
.title-text-tips{
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC, Monaco, Avenir, Helvetica, serif;
  font-weight: 400;
  color: #F9AED5;
  line-height: 22px;
  letter-spacing: 1px;
}


.mobile-box{
  background: #FFFFFF;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.04);
  border-radius: 10px;
  margin: 0 15px 0 15px;
  text-align: center;
  border:solid 1px transparent;
}
.app-info{
  text-align: center;
}
.app-info .app-logo{
  width: 72px;
  height: 72px;
  border-radius: 15px;
  display: block;
  margin: -36px auto auto;
}
.app-info .app-name{
  width: 62px;
  display: block;
  margin: 12px auto auto;
}

.download-text{
  font-size: 14px;
  font-family: PingFang SC, Monaco, Avenir, Helvetica, serif;
  font-weight: 400;
  color: #75778A;
  line-height: 20px;
  letter-spacing: 3px;
  margin-top: 12px;
  white-space: nowrap;
  overflow: hidden;
}

.mobile-box .qr-box .qr-img{
  display: none !important;
}

.version-info {
  color: #666666;
  font-size: 14px;
  line-height: 1.6;
}

.version-info > div {
  display: inline-block;
}

.platform {
  border-right: 1px solid transparent;
  padding-right: 10px;
  margin-right: 5px;
  display: none !important;
}
.platform-notice {
  border-right: 1px solid transparent;
  padding-right: 10px;
  margin-right: 5px;
  color:#CACBCC;
  margin-top: 10px;
}

.version-info .version {
  margin-left: 5px;
}

.install-box {
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.install-btn {
  background-color: #FF5CB0;
  line-height: initial;
  display: inline-block;
  color: white;
  text-decoration: none;
  width:92px;
  padding: 8px 0;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid #FF5CB0;
}
.install-btn.normal {
  color:#FF74AC;
  border: 1px solid #FF74AC;
  background-color: white;
}

.install-btn + .install-btn {
  margin-left: 10px;
}

.install-btn:hover {
  opacity: .6;
}

.install-notice {
  margin: 12px 0;
  font-size: 14px;
  font-family: PingFang SC, Monaco, Avenir, Helvetica, serif;
  font-weight: 400;
  color: #FFA8CB;
  line-height: 20px;
}

.install-notice a {
  color: #FF348C;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
}

.mask img {
  width: 100%;
}

.now-download {
  width: 100%;
  color: #000000;
  margin-bottom: 14px;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
}

.a1 {
  font-size: 15px;
  color: #999;
  text-align: center;
  margin-bottom: 17px;
}

.a2 {
  font-size: 15px;
  text-align: center;
  margin-bottom: 17px;
  font-weight: 500;
  color: #FF3333;
}

.course_p {
  max-width: 340px;
  height: 44px;
  line-height: 44px;
  padding: 0 11px;
  margin: 0 auto;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(to right, #FF7B52, #F7C46B);
}

.alert-box {
  max-width: 500px;
  position: absolute;
  width: 86%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #f9f9f9;
  height: auto;
  padding: 3%;
  border-radius: 11px;
}

.close-btn2 {
  width: 28px;
  height: 28px;
  position: absolute;
  z-index: 3;
  background: url(~@/assets/images/close.png) no-repeat center;
  background-size: 100% auto;
  top: auto;
  bottom: -35px;
  left: 50%;
  margin-left: -14px;
}

.qr-box {
  margin-top: 12px;
}

.qr-box img {
  width: 150px;
  padding: 3px;
  border-radius: 10px;
}
.qr-box .version{
  font-size: 12px;
  font-family: PingFang SC, Monaco, Avenir, Helvetica, serif;
  font-weight: 400;
  color: #CACBCC;
  line-height: 17px;
  margin-top: 8px;
  margin-bottom: 32px;
}

.both-platform {
  text-align: center;
  margin-top: 10px;
  border:solid 1px transparent;
}
.platform-box, .platform-version {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  width: 320px;
  color:#FFA8CB;
  margin: 20px auto auto;
}

.platform-version {
  margin-top: 10px;
}

.both-platform .app-box {
  width: 80px;
  height: 80px;
  border-radius: 60px;
  background: #FF5CB0;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
  color: white;
}

.split {
  color: #eeeeee;
}

.platform-version .version-box {
  width: 100px;
  text-align: center;
  font-size: 13px;
}

.android-box .app-icon {
  background: url("~@/assets/images/index_icon_android.png") center center no-repeat;
}

.ios-box .app-icon {
  background: url("~@/assets/images/index_icon_ios.png") center center no-repeat;
}

.both-platform{
  background: #FFFFFF;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.04);
  border-radius: 10px;
  width:460px;
  margin: auto;
}

.both-platform .app-icon {
  height: 30px;
  width: 30px;
  margin: auto;
  background-size: 30px auto;
  transition: all .6s;
}

.both-platform .app-text {
  margin-top: 5px;
  line-height: 24px;
  transition: all .6s;
  font-size: 11px;
}

.both-platform .download-now {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  transition: all .6s;
  opacity: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.verify-tips{
  padding:5px 15px;
  font-size: 14px;
  color:#a9a9a9;
  line-height: 24px;
}
.verify-tips span{
  color:#f35365;
}

.app-box:hover .download-now {
  opacity: 1;
}

.app-box:hover .app-icon {
  opacity: 0;
}

.app-box:hover .app-text {
  opacity: 0;
}

.both-platform .qr-box{
  color:#999999;
}
.code-row{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.send-code{
  font-size: 14px;
  white-space: nowrap;
  padding:0 5px;
  color:#f35365;
}
.send-code.disabled {
  color:#aaaaaa;
}
</style>