import axios from 'axios';
import qs from 'qs';


axios.defaults.transformRequest=[
    function(data){
        return qs.stringify(data);
    }
]
axios.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    return Promise.reject(error);
});


export async function post(url, data) {
    if (!data) {
        data = {};
    }
    return await axios.post(
        url,
        data
    );
}


export async function get(url, config) {
    return await axios.get(
        url, config
    );
}

export async function getNoCache(url) {
    return await axios.get(
        url, {headers:{"Cache-Control":"no-cache, no-store, must-revalidate","Pragma":"no-cache","Expires":"0"}}
    );
}
