import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import {
    Toast,
    Field,
    CellGroup,
    Dialog
} from "vant";

import 'vant/lib/index.css';

Toast.allowMultiple(true);
createApp(App).use(VueAxios, axios)
    .use(Toast)
    .use(Field)
    .use(CellGroup)
    .use(Dialog).mount('#app')
